const header = [
  {
    title: 'FORMULA 1 STC SAUDI ARABIAN GRAND PRIX 2021',
    description: 'Hamilton survives Verstappen contact to win dramatic Saudi Arabian Grand Prix.',
    videoSrc: 'https://aw-media.bestseat360.tv/MediaConversions/f1-jeddah/package1-1-4-12/SAU_P01pipR-1.mp4',
    brand: 'f1',
    config: '2022-australian-gp-package-1',
    type: '360'
  },
  {
    title: 'FORMULA E BERLIN EPRIX 2021',
    description: 'The Audi duo of Di Grassi and Rast overtake Vergne.',
    poster: '/player/assets/brand/fe/all-events/formula-e-demo.jpg',
    brand: 'fe',
    config: 'formula-e-demo',
  },
  {
    title: 'INDYCAR SERIES: FIRESTONE GRAND PRIX',
    description: 'The Firestone Grand Prix of St. Petersburg is an IndyCar Series race held in St. Petersburg, Florida.',
    poster: '/player/assets/brand/indycar/all-events/firestone-gp.jpg',
    brand: 'indycar',
    config: 'firestone-gp',
  },
  {
    title: 'Revival 2022 Race 5: St. Mary\'s Trophy',
    description: 'The St. Mary’s Trophy presented by Motul was a two-parter at the 2021 Goodwood Revival. Little met large in a battle for supremacy around our Motor Circuit.',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/gw/StMarysTrophy_Part1_landing.mp4',
    brand: 'gw',
    logo: '/player/assets/brand/gw/gw-logo.png',
    config: '2022-gw-revival-race-5',
    type: '360'
  },
  {
    title: 'Revival 2022 Race 8: Stirling Moss Memorial Trophy',
    description: 'The most beautiful race in the world? We’ve said it before and we’ll say it again, especially this year with the added emotional element of a tribute to Mr Goodwood.',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/gw/Race8_Landing2.mp4',
    brand: 'gw',
    logo: '/player/assets/brand/gw/gw-logo.png',
    config: '2022-gw-revival-race-8',
    type: '360'
  },
  {
    title: 'Revival 2022 Race 12: RAC TT Celebration',
    description: 'Is there a more dramatic grid than the RAC TT Celebration? The sheer sound as cars roar away from the line is something that needs to be seen!',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/gw/rac-tt-landing.mp4',
    brand: 'gw',
    logo: '/player/assets/brand/gw/gw-logo.png',
    config: '2022-gw-revival-race-12',
    type: '360'
  },

  {
    title: 'Revival 2022 Race 5: St. Mary\'s Trophy',
    description: 'The St. Mary’s Trophy presented by Motul was a two-parter at the 2021 Goodwood Revival. Little met large in a battle for supremacy around our Motor Circuit.',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/giltrap/StMarysTrophy_Part1_landing.mp4',
    brand: 'giltrap',
    logo: '/player/assets/brand/giltrap/gw-logo.png',
    config: '2022-gw-revival-race-5',
    type: '360'
  },
  {
    title: 'Revival 2022 Race 8: Stirling Moss Memorial Trophy',
    description: 'The most beautiful race in the world? We’ve said it before and we’ll say it again, especially this year with the added emotional element of a tribute to Mr Goodwood.',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/giltrap/Race8_Landing2.mp4',
    brand: 'giltrap',
    logo: '/player/assets/brand/giltrap/gw-logo.png',
    config: '2022-gw-revival-race-8',
    type: '360'
  },
  {
    title: 'Revival 2022 Race 12: RAC TT Celebration',
    description: 'Is there a more dramatic grid than the RAC TT Celebration? The sheer sound as cars roar away from the line is something that needs to be seen!',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/giltrap/rac-tt-landing.mp4',
    brand: 'giltrap',
    logo: '/player/assets/brand/giltrap/gw-logo.png',
    config: '2022-gw-revival-race-12',
    type: '360'
  },
  {
    title: 'TOYOTA RACING SERIES NZ GRAND PRIX',
    description: 'Watch the Toyota Racing Series: New Zealand Grand Prix here!',
    poster: '/player/assets/brand/trs/all-events/2021-nz-grand-prix.jpg',
    brand: 'trs',
    config: '2021-nz-grand-prix',
  },
  {
    title: 'Penfolds Victoria Derby',
    description: 'Victoria Derby Day is often referred to as “the day for racing purists” and is widely considered to be the most prestigious day of the Melbourne Cup Carnival.',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/vrc/2022-melbourne-cup/penfolds-victoria-derby-day.mp4',
    brand: 'vrc',
    config: '2022-penfolds-victoria-derby&raceId=439',
    type: '360'
  },
  {
    title: 'Lexus Melbourne Cup',
    description: 'Known as “the race that stops a nation”, the Melbourne Cup is the most famous handicap event in the world.',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/vrc/2022-melbourne-cup/lexus-melbourne-cup-day.mp4',
    brand: 'vrc',
    config: '2022-lexus-melbourne-cup&raceId=467',
    type: '360'
  },
  {
    title: 'Kennedy Oaks',
    description: 'Traditionally known as Ladies Day, Kennedy Oaks Day is held every year on the Thursday following Melbourne Cup Day.',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/vrc/2022-melbourne-cup/kennedy-oaks-day.mp4',
    brand: 'vrc',
    config: '2022-kennedy-oaks&raceId=481',
    type: '360'
  },
  {
    title: 'TAB Champions Stakes',
    description: 'Champions Stakes Day – formerly Mackinnon Stakes Day – is the final day of the Melbourne Cup Carnival and is held on the Saturday following the Melbourne Cup.',
    videoSrc: 'https://aw-media.bestseat360.tv/assets/brand/vrc/2022-melbourne-cup/tab-champions-stakes-day.mp4',
    brand: 'vrc',
    config: '2022-tab-champions-stakes&raceId=501',
    type: '360'
  },
  {
    title: 'FIFA 2022 Qualifiers: Australia vs Peru',
    description: 'Watch as Australia and Peru go head to head for a spot in the FIFA World Cup!',
    poster: '/player/assets/brand/fifa/2022-fifa-world-cup/aus-vs-per.jpg',
    brand: 'fifa',
    config: 'aus-vs-per',
    type: '360'
  },
  {
    title: 'Hippodrome de Grosbois',
    description: 'Watch Gilles Curens and Lucas Cueff ride around the Grosbois track',
    poster: '/player/assets/brand/hdg/all-events/gilles-curens.jpg',
    brand: 'hdg',
    config: 'gilles-curens',
    type: '360'
  },
  {
    title: 'Alexandra Park Horse Racing',
    description: 'Watch the Friday Night Races at Alexandra Park in 360!',
    videoSrc: 'https://aw-media.bestseat360.tv/media/assets/video.mp4',
    brand: 'ap',
    config: 'friday-night-races/heat-3-race-1',
    type: '360'
  },
  {
    title: 'TOYOTA TEST DRIVE',
    description: 'Experience a virtual test drive in 360 inside the Toyota Yaris Hybrid!',
    poster: '/player/assets/brand/toyota/all-events/test-drive-1.jpg',
    brand: 'toyota',
    config: 'test-drive-1',
    type: '360'
  },
  {
    title: 'BESTSEAT360 Two Car Race',
    description: 'One of BestSeat360\'s first test events showcasing the excitement of motorsport in 360.',
    poster: '/player/assets/brand/bestseat/test-events/toyota-two-car-race.jpg',
    brand: 'trs',
    config: 'toyota-two-car-race',
    type: '360'
  },
  {
    title: 'BESTSEAT360 Cricket',
    description: 'Explore cricket in 360 with BestSeat360\'s cricket test!',
    poster: '/player/assets/brand/bestseat/test-events/360-cricket.jpg',
    brand: 'bestseat',
    config: 'cricket',
    type: '360'
  },
  {
    title: 'WRC Rallye Monte-Carlo',
    description: 'The most unpredictable rally of the year. The skill to drive slowly as fast as possible is the key. Little is simple in the high mountains!',
    poster: '/player/assets/brand/bestseat/test-events/rally-racing.jpg',
    brand: 'wrc',
    config: 'rally-racing',
  },
]

export default header