<template>
  <b-card-code title="Select Category" class="mb-1 container p-0">
    <swiper class="swiper-responsive-breakpoints animate__animated animate__fadeInLeft" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
      <swiper-slide v-for="(brand,index) in brands" :key="index">
        <div class="card bg-dark w-80 h-96 bg-gray-800 rounded mr-8 mb-0 inline-block whitespace-normal text-center" :class="brand.path === brandId ? 'active' : 'inactive'">
          <span class="brand-card rounded d-inline-block" @click="switchBrand(brand.path)">
            <img :src="brand.logo" class="brand-logo">
          </span>
        </div>
      </swiper-slide>

      <!-- Add Arrows -->
      <div slot="button-next" class="swiper-button-next" />
      <div slot="button-prev" class="swiper-button-prev" />
    </swiper>
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import { codeResponsive } from '@/views/code'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCardCode,
    BImg,
  },
  props: ['brands', 'brandId'],
  data() {
    return {
      codeResponsive,
      swiperOptions: {
        slidesPerView: 4,
        spaceBetween: 20,
        initialSlide: this.brands.findIndex(brand => brand.path === this.brandId) || 0,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          1200: {
            slidesPerView: 5,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
        },
      },
    }
  },
  methods: {
    switchBrand(brand) {
      this.$router.push(`/brand/${brand}`)
      this.$emit('updateBrand', brand)
    }
  }
}
</script>

<style scoped>
.title {
  height: 70px;
}

.bg-dark:hover {
  background-color: #353937 !important;
}

.brand-card {
  height: 95px;
  cursor: pointer;
}

.brand-logo {
  max-width: 120px;
  max-height: 50px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.active {
  background: #ef4136 !important;
}

.inactive {
  opacity: 0.75;
  border: 1px #333 solid;
}

.inactive:hover, .inactive:focus {
  background: #333;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next::after {
  display: none;
}

.swiper-button-prev {
  width: 35px;
  height: 35px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35'%3E%3Cg transform='translate(-352.078 135.654) rotate(180)'%3E%3Cg transform='translate(-387.078 100.654)' fill='rgba(255,255,255,0.25)' stroke='%23e8e8e8' stroke-width='2'%3E%3Ccircle cx='17.5' cy='17.5' r='17.5' stroke='none'/%3E%3Ccircle cx='17.5' cy='17.5' r='16.5' fill='none'/%3E%3C/g%3E%3Cpath d='M2.762,8.011,8.826,1.952A1.145,1.145,0,0,0,7.2.335L.334,7.2A1.143,1.143,0,0,0,.3,8.779l6.9,6.913a1.145,1.145,0,1,0,1.622-1.617Z' transform='translate(-363.75 125.6) rotate(180)' fill='%23e8e8e8'/%3E%3C/g%3E%3C/svg%3E") !important;
}

.swiper-button-next {
  width: 35px;
  height: 35px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='35' viewBox='0 0 35 35'%3E%3Cg transform='translate(387.078 -100.654)'%3E%3Cg transform='translate(-387.078 100.654)' fill='rgba(255,255,255,0.25)' stroke='%23e8e8e8' stroke-width='2'%3E%3Ccircle cx='17.5' cy='17.5' r='17.5' stroke='none'/%3E%3Ccircle cx='17.5' cy='17.5' r='16.5' fill='none'/%3E%3C/g%3E%3Cpath d='M2.762,8.011,8.826,1.952A1.145,1.145,0,0,0,7.2.335L.334,7.2A1.143,1.143,0,0,0,.3,8.779l6.9,6.913a1.145,1.145,0,1,0,1.622-1.617Z' transform='translate(-363.75 125.6) rotate(180)' fill='%23e8e8e8'/%3E%3C/g%3E%3C/svg%3E") !important;
}

.card {
  box-shadow: none;
}
</style>