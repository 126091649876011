<template>
  <div class="mb-3 pt-1" ref="player">
    <div class="row ml-0 mr-0 mb-1 align-items-center">
      <div class="col-9 p-0">
        <a class="text-decoration-underline" :href="playerUrl">
          <h5 class="inline-block">{{ playerTitle }}</h5>
        </a>
      </div>
      <div class="col-3 text-right p-0">
        <div class="btn btn-outline-dark ml-auto close-icon" @click="$emit('hidePlayer')">
          <feather-icon
            icon="XIcon"
            size="16"
            class="align-text-top "
          />
        </div>
      </div>
    </div>
    <b-embed
      type="iframe"
      aspect="16by9"
      :src="playerUrl"
      allowfullscreen
      class="p-0 player-window animate__animated animate__fadeIn"
    />
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BEmbed } from 'bootstrap-vue'

export default {
  components: {
    BCardCode,
    BEmbed,
  },
  mounted () {
    setTimeout(() => {
      this.$refs.player.scrollIntoView({ behavior: "smooth" })
    }, 500)
  },
  props: ['playerUrl', 'playerTitle'],
}
</script>

<style scoped>
.player-window {
  border: 10px #212423 solid;
}

.close-icon {
  background: #ef4136;
}

a {
  color: #fff;
  opacity: 1;
  text-decoration: underline !important;
}

a:hover, a:focus, a:active {
  color: #fff;
  opacity: 0.75
}
</style>
