const brands = [
  {
    name: 'BestSeat360',
    logo: '/player/assets/brand/bestseat/logo.png',
    path: 'bestseat',
    events: eventsList.bestseat
  },
  
  {
    name: 'Formula 1',
    logo: '/player/assets/brand/f1/logo.png',
    path: 'f1',
    events: eventsList.f1
  },

  {
    name: 'Formula E',
    logo: '/player/assets/brand/fe/logo.png',
    path: 'fe',
    events: eventsList.fe
  },

  {
    name: 'IndyCar',
    logo: '/player/assets/brand/indycar/logo.png',
    path: 'indycar',
    events: eventsList.indycar
  },

  {
    name: 'Toyota Racing Series',
    logo: '/player/assets/brand/trs/logo.png',
    path: 'trs',
    events: eventsList.trs
  },

  {
    name: 'Toyota Test Drive',
    logo: '/player/assets/brand/toyota/logo.png',
    path: 'toyota',
    events: eventsList.toyota
  },

  {
    name: 'Goodwood',
    logo: '/player/assets/brand/gw/logo.png',
    path: 'gw',
    ctaColor: '#a5e7d4',
    fontColor: '#000',
    events: eventsList.gw
  },

  {
    name: 'Giltrap',
    logo: '/player/assets/brand/giltrap/logo.png',
    topRightImage: '/player/assets/brand/giltrap/logo.png',
    path: 'giltrap',
    ctaColor: '#a5e7d4',
    fontColor: '#000',
    events: eventsList.giltrap
  },

  {
    name: 'Melbourne Cup',
    logo: '/player/assets/brand/vrc/logo.png',
    path: 'vrc',
    events: eventsList.vrc
  },

  {
    name: 'Alexandra Park',
    logo: '/player/assets/brand/ap/logo.png',
    path: 'ap',
    events: eventsList.ap
  },

  {
    name: 'Hippodrome de Grosbois',
    logo: '/player/assets/brand/hdg/logo.png',
    path: 'hdg',
    events: eventsList.hdg
  },

  {
    name: 'FIFA World Cup',
    logo: '/player/assets/brand/fifa/logo.png',
    path: 'fifa',
    events: eventsList.fifa
  },

  {
    name: 'World Rally Championship',
    logo: '/player/assets/brand/wrc/logo.png',
    path: 'wrc',
    events: eventsList.wrc
  },

  {
    name: 'Sideline',
    logo: '/player/assets/brand/sl/logo.png',
    path: 'sl',
    events: eventsList.sl
  },
]

export default brands