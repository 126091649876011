<template>
<div class="mb-2">
  <swiper class="swiper-navigations" :options="swiperOptions" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'">
    <swiper-slide v-for="(header,index) in headers" :key="index">
      <div class="d-flex p-6 header-container">
        <div class="pl-2 pb-1 solo-grid z-20 card-content col-10 offset-1 col-lg-6 col-xl-4 align-self-end align-self-lg-center align-self-xl-center mr-0 mr-md-5">
          <img v-if="header.logo" class="headerLogo mb-1" :src="header.logo" />
          <h2 class="font-weight-bold mb-1">
            {{ header.title }}
          </h2>
          <p class="text-light mb-2 mb-md-2">{{ header.description }}</p>
          <a :href="`https://player.bestseat360.tv/player.html?t=1&config=${header.brand}/${header.config}`" target="_blank" class="btn btn-primary">
            Watch Race <span v-if="header.type === '360'"><img class="icon inline-block" :src="icon" /> 360</span>
          </a>
        </div>
        <div class="tint-left position-absolute w-100 h-100 d-none d-lg-block d-xl-block"></div>
        <div class="tint-bottom position-absolute w-100 h-100 d-block d-lg-none d-xl-none"></div>
        <video ref="headerVideo" autoplay="autoplay" muted loop playsinline class="header-video solo-grid embed-responsive-item w-100 h-100 swiper-lazy" :src="header.videoSrc" :poster="header.poster || ''" />
      </div>
    </swiper-slide>

    <!-- Add Arrows -->
    <div slot="button-next" class="swiper-button-next" />
    <div slot="button-prev" class="swiper-button-prev" />
  </swiper>
</div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import { codeNavigation } from '@/views/code'
import headers from '@/config/config-headers'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCardCode,
    BImg,
  },
  props: ['brandId'],
  data() {
    return {
      codeNavigation,
      headers: headers,
      icon: require('@/assets/icon.svg'),
      swiperOptions: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        autoplay: {
          delay: 8000,
        },
      },
    }
  },
  created() {
    this.filterHeaders(this.brandId)
  },
  watch: {
    brandId: function(newBrandId) {
      console.log(this.$refs.headerVideo)
      this.$refs.headerVideo[0].src = ''
      this.filterHeaders(newBrandId) 
    }
  },
  methods: {
    filterHeaders() {
      if (this.brandId) {
        const headersHasBrand = headers.find(header => header.brand === this.brandId) ? true : false
        /* Only show headers belonging to active brand OR all if none are available */
        this.headers = headersHasBrand ? headers.filter(header => header.brand === this.brandId) : headers
      } else {
        /* Show all headers if not browsing a specific brand */
        this.headers = headers
      }
    }
  }
}
</script>

<style scoped>
  .header-container {
    height: 80vh;
    display: grid !important;
  }

  .header-video {
    opacity: 0.75;
    max-height: 80vh;
    object-fit: cover;
  }

  .card-content {
    z-index: 10;
  }
  
  .headerLogo {
    max-width:180px;
    max-height: 24px;
  }

  h2 {
    font-family: Helvetica, sans-serif;
    font-size: 2rem;
    color: #ffffff;
  }

  .swiper-button-prev::after {
    display: none;
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev, .swiper-button-next {
    width: 58px;
    height: 58px;
    border-radius: 100px;
    transition: 0.3s;
    border: 1px solid rgba(128, 128, 128, 1);
    background: rgba(30, 30, 30, 0.25);
  }

  .swiper-button-prev {
    left: 2rem;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 36 36' style='enable-background:new 0 0 36 36;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M20,11.3c0.3,0,0.5,0.1,0.7,0.3c0.4,0.4,0.4,1,0,1.4l-4.3,4.3c-0.4,0.4-0.4,1,0,1.4l4.3,4.3 c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-5.7-5.7c-0.4-0.4-0.4-1,0-1.4l5.7-5.7C19.5,11.4,19.8,11.3,20,11.3z'/%3E%3C/svg%3E%0A") !important;
  }
  
  .swiper-button-next {
    width: 58px;
    height: 58px;
    right: 2rem;
    transition: 0.25s;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 36 36' style='enable-background:new 0 0 36 36;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M16,24.7c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4l4.3-4.3c0.4-0.4,0.4-1,0-1.4L15.3,13 c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0l5.7,5.7c0.4,0.4,0.4,1,0,1.4l-5.7,5.7C16.5,24.6,16.2,24.7,16,24.7z'/%3E%3C/svg%3E%0A") !important;
  }

  .swiper-button-prev:hover, .swiper-button-next:hover {
    background: var(--cta-color);
    border-color: var(--cta-color);
  }

  .icon {
    width: 20px;
    height: 20px;
    margin: 0 5px;
  }

  .solo-grid {
    grid-column: 1;
    grid-row: 1;
  }

  .z-10 {
    z-index: 10;
  }

  .z-20 {
    z-index: 20;
  }
  .tint-left {
    z-index:1;
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 69%, rgba(0,0,0,1) 100%);
  }
  .tint-bottom {
    z-index:1;
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 69%, rgb(0, 0, 0) 100%);
  }
</style>