<template>
  <div>
    <hero-carousel :brandId="brandId" />
    <div v-if="showContent || brandId">
      <brands-carousel v-if="showBrands" :brands="brands" :brandId="brandId" @updateBrand="updateBrand($event)" />
      <videos-carousel v-for="row in rows" :key="row.name" :row="row" :brandId="brandId"  @updateBrand="updateBrand($event)" :activePlayerUrl="activePlayerUrl" @updateActivePlayerUrl="updateActivePlayerUrl($event)"/>
    </div>
  </div>
</template>

<script>
import HeroCarousel from '@/components/HeroCarousel.vue'
import VideosCarousel from '@/components/VideosCarousel.vue'
import BrandsCarousel from '@/components/BrandsCarousel.vue'
import brands from '@/config/config-brands'
import contentSettings from '@/config/config-app'

export default {
  components: {
    HeroCarousel,
    VideosCarousel,
    BrandsCarousel
  },
  data() {
    return {
      brandId: this.$route.params.brand || null,
      brands: brands,
      rows: null,
      showBrands: true,
      showContent: contentSettings.showHomepageContent,
      activePlayerUrl: null
    }
  },
  created() {
    /* Hide brands selector if coming in from a brand URL */
    if (this.brandId) {
      this.showBrands = false
    }
    this.updateBrandTheme()
    this.listVideos()
    this.setActiveBrand()
  },
  watch: {
    brandId: function() {
      this.updateBrandTheme()
    }
  },
  methods: {
    updateBrand(brand) {
      this.brandId = brand
      this.listVideos()
      this.setActiveBrand()
    },
    setActiveBrand() {
      const activeBrand = this.brands.find(brand => brand.path === this.brandId)
      if (this.brandId) {
        // store brand object in localStorage for use in navbar
        localStorage.setItem('activeBrand', JSON.stringify(activeBrand))
      } else {
        // clear activeBrand if no brand is being viewed
        localStorage.removeItem('activeBrand')
      }
    },
    listVideos() {
      if (this.brandId) {
        
        /* If browsing a single brand - list rows by EVENT */
        this.rows = brands.find(brand => brand.path === this.brandId).events

        /* Sort events alphabetically by name */
        // this.rows.sort((a,b) => a.name.localeCompare(b.name))

        /* Sort races alphabetically by name */
        // this.rows.map(row => {
        //   row.races.sort((a,b) => a.name.localeCompare(b.name))
        // })
        this.rows.map(row => {
          row.races.sort((a, b) => {
            const orderA = a.order || Infinity;
            const orderB = b.order || Infinity;

            if (orderA !== orderB) {
              return orderA - orderB;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
        })

      } else {
        /* Else, list rows by BRAND */
        this.rows = brands.map(brand => {
          /* Extract all races from a brand into a single array */
          const allRaces = brand.events.reduce((races, event) => {
            return ([...races, ...event.races])
          }, [])
          
          return({
            name: brand.name,
            path: brand.path,
            races: allRaces
          })
        })
      }
    },
    updateBrandTheme() {
      const currentBrand = this.brands.find(brand => brand.path === this.brandId)
      const docStyles = document.documentElement.style

      if (this.brandId && currentBrand.ctaColor && currentBrand.fontColor) {
        docStyles.setProperty('--cta-color', currentBrand.ctaColor)
        docStyles.setProperty('--font-color', currentBrand.fontColor)
      } else {
        // set default styles
        docStyles.setProperty('--cta-color', '#ef4136')
        docStyles.setProperty('--font-color', '#fff')
      }
    },
    updateActivePlayerUrl(playerUrl) {
      this.activePlayerUrl = playerUrl
    }
  }
}
</script>

<style>
.navbar {
  position: absolute !important;
}

.btn-primary {
  background-color: var(--cta-color) !important;
  color: var(--font-color) !important;
  border-color: var(--cta-color) !important;
  font-weight: bold;
}

.btn-primary:focus, .btn-primary:hover, .btn-primary:active {
  background-color: var(--cta-color) !important;
  box-shadow: 0 8px 25px -8px var(--cta-color) !important;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
}

h4 {
  font-size: 1.5rem !important;
}

.card {
  box-shadow: none;
  background: none;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
